document.addEventListener('DOMContentLoaded', function () {
  const swiperElement = document.querySelector('.results-swiper');

  if (swiperElement) {
    const initSwiper = () => {
      const resultsSwiper = new Swiper(swiperElement, {
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        pagination: {
          el: '.results-swiper-pagination',
          clickable: true,
        },
        navigation: {
          prevEl: '.results-swiper-btn-prev',
          nextEl: '.results-swiper-btn-next',
        },
        loop: true,
        speed: 600,
        slidesPerView: 1,
        spaceBetween: 16,
        breakpoints: {
          1280: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      });
    };

    // Перевірка на наявність Swiper
    if (typeof Swiper !== 'undefined') {
      initSwiper();
    } else {
      // Якщо Swiper ще не завантажений, спробуємо ще раз, коли він буде готовий
      window.addEventListener('load', initSwiper);
    }
  }
});
